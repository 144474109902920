export const getResponseErrorMessage = (error) => {
  let message = '';
  if (error.response?.status === 422) {
    const messages = error.response.data.message;
    Object.entries(messages).forEach(([key, item]) => {
      message += `<br>${Array.isArray(item) ? item.join('<br>') : item}`;
    });
  } else if (error.response?.status === 403) {
    message += '<br><span class="text-primary">Acesso negado</span>';
  } else {
    console.error(error);
  }
  return message;
}

export const routeCompile = (route, parameters = {}) => {
  let newRoute = route;
  Object.keys(parameters).forEach((item) => {
    newRoute = String(newRoute).replace(item, parameters[item]);
  });
  return newRoute;
}
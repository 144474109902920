import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import {businessCard, contactNotFound} from "./config/routes";
import BusinessCard from "./pages/BusinessCard";
import NotFound from "./pages/NotFound";

export default () => (
  <BrowserRouter>
    <Routes>
      <Route path={businessCard} element={<BusinessCard />} />
      <Route path={contactNotFound} element={<NotFound />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);
import styled from 'styled-components';

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem 4.5rem;
  width: 100%;
  box-sizing: border-box;
`;

export const ContainerSocialMedia = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 0.5rem;
  box-sizing: border-box;
  font-size: 1.5rem;
`;
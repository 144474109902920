import React from "react";
import Routes from "./routes";
import { createGlobalStyle } from 'styled-components';
import normalize from 'normalize.css';
const GlobalStyle = createGlobalStyle`
  body {
    font-family: "Open Sans";
  }
  .ms-2 {
    margin-left: 0.5rem;
  }
  .ms-3 {
    margin-left: 1rem;
  }
  ${normalize}
`

export default function App () {
  return <React.Fragment>
    <GlobalStyle />
    <Routes />
  </React.Fragment>;
}
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../services/api";
import Load from "../../components/Load";
import {FIND_BY_ID_OR_URLID} from "../../config/routesApi";
import { routeCompile } from "../../utils/functions";
import {Avatar} from "../../components/Avatar";
import {Biography, Container, ContainerAvatar, ContainerInformation, JobTitle, Name, Wrapper} from "./style";
import Button from "../../components/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { Buffer } from "buffer";
import Footer from "../../components/Footer";
import {contactNotFound} from "../../config/routes";

export default function BusinessCard () {
  const { id } = useParams();
  const navigate = useNavigate();
  const [businessCard, setBusinessCard] = useState({});
  const [load, setLoad] = useState(false);

  const getBusinessCard = useCallback(async () => {
    setLoad(true);
    await api
      .request({
        url: routeCompile(FIND_BY_ID_OR_URLID.url, { '{idOrUrlid}': id }),
        method: FIND_BY_ID_OR_URLID.method
      })
      .then(response => {
        const { data } = response.data;
        const contact = JSON.parse(data.contact) || [];
        const social_network = JSON.parse(data.social_network) || [];
        setBusinessCard({...data, contact, social_network});
        changeUrlToUseUrlid(data.urlid);
      })
      .catch(error => {
        navigate(contactNotFound);
      })
      .then(() => setLoad(false))
  });

  useEffect(() => {
    getBusinessCard()
  }, [id]);

  function changeUrlToUseUrlid(urlid) {
    const isUUID = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(id);

    if (isUUID && urlid) {
      const url = new URL(window.location);
      url.pathname = urlid;
      window.history.pushState({}, '', url);
    }
  }

  function getContact() {
    return Array.from(businessCard?.contact || []).map((contact, idx) => {
      return <React.Fragment key={`contact-${idx}`}>
        {contact.email && <Button href={`mailto:${contact.email}`} target="_blank">
          <FontAwesomeIcon icon={faEnvelope}/>
          <span className="ms-2">E-mail</span>
        </Button>}
        {contact.telephone && <Button href={`tel:${contact.telephone}`} target="_blank">
          <FontAwesomeIcon icon={faPhone}/>
          <span className="ms-2">Telefone</span>
        </Button>}
      </React.Fragment>
    });
  }

  function getSocialNetwork() {
    return Array.from(businessCard?.social_network || []).map((socialNetwork, idx) => {
      let whatsapp = (socialNetwork.whatsapp || '').match(/\d/g);
      whatsapp = Array.isArray(whatsapp) ? whatsapp.join('') : '';
      return <React.Fragment key={`social-network-${idx}`}>
        {socialNetwork.linkedin && <Button href={socialNetwork.linkedin} target="_blank">
          <FontAwesomeIcon icon={faLinkedinIn} />
          <span className="ms-2">LinkedIn</span>
        </Button>}
        {whatsapp && <Button href={`https://api.whatsapp.com/send/?phone=55${whatsapp}`} target="_blank">
          <FontAwesomeIcon icon={faWhatsapp} />
          <span className="ms-2">WhatsApp</span>
        </Button>}
      </React.Fragment>
    });
  }

  function getImageDefault () {
    const buff = Buffer.from(
      `<svg width="300" height="300" x="300" y="300" overflow="hidden" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300"><g style="pointer-events:none"><rect x="-1" y="-1" width="300" height="300" fill="#bf2c37" style="pointer-events:inherit"></rect></g><g style="pointer-events:all"><text fill="#ffffff" stroke-width="0" x="125.79951129461602" y="151.50271749017597" font-size="45" font-family="Lato, sans-serif" text-anchor="start" xml:space="preserve" stroke-dasharray="none" fill-opacity="1" transform="matrix(1.75,0,0,1.7,-109.41,-80.19)" stroke="#000">${String(businessCard.name).substring(0, 2).toUpperCase()}</text></g></svg>`
    );
    return `data:image/svg+xml;base64,${buff.toString('base64')}`
  }

  return (
    <Wrapper bgDiamond>
      <Container>
        <ContainerAvatar>
          {businessCard.name && <Avatar src={businessCard.image || getImageDefault()} alt={businessCard.name} size="7.5em"/>}
        </ContainerAvatar>

        <ContainerInformation>
          <Name>{businessCard.name}</Name>
          <JobTitle>{businessCard.job_title}</JobTitle>
          {businessCard.biography && <Biography>{businessCard.biography}</Biography>}
          {getContact()}
          {getSocialNetwork()}
        </ContainerInformation>
      </Container>
      <Footer />
      {load && <Load />}
    </Wrapper>
  );
}
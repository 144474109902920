import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API_PUBLIC,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'X-Requested-With': 'XMLHttpRequest'
  },
})

export default api
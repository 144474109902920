import React from "react";
import {Title, Wrapper} from "./style";
import Footer from "../../components/Footer";

export default function NotFount () {
  return (
    <Wrapper bgDiamond>
      <Title>Desculpe, não conseguimos encontrar o contato.</Title>
      <Footer />
    </Wrapper>
  );
}
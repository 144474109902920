import React from "react";
import logoValeSul from "../../assets/img/marca-vale-sul-shopping.svg";
import {ContainerSocialMedia, FooterContainer} from "./style";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faYoutube } from '@fortawesome/free-brands-svg-icons';

export default function Footer () {
  return (
    <FooterContainer>
      <a href="https://valesul.shopping"><img width="120" src={logoValeSul} alt="Marca Vale Sul Shopping" /></a>
      <ContainerSocialMedia>
        <a target="_blank" href="https://www.instagram.com/valesulshopping"><FontAwesomeIcon color="#fff" icon={faInstagram} /></a>
        <a target="_blank" href="https://www.facebook.com/valesulshopping"><FontAwesomeIcon className="ms-3" color="#fff" icon={faFacebook} /></a>
        <a target="_blank" href="https://www.youtube.com/user/valesulshopping"><FontAwesomeIcon className="ms-3" color="#fff" icon={faYoutube} /></a>
        <a target="_blank" href="https://www.google.com.br/maps/place/Av.+Andr%C3%B4meda,+227+-+Jardim+Sat%C3%A9lite,+S%C3%A3o+Jos%C3%A9+dos+Campos+-+SP/@-23.2166646,-45.8942275,17z/data=!3m1!4b1!4m5!3m4!1s0x94cc4a88bd5f1495:0x457529250889d79c!8m2!3d-23.2166646!4d-45.8920388"><FontAwesomeIcon className="ms-3" color="#fff" icon={faLocationDot} /></a>
      </ContainerSocialMedia>
    </FooterContainer>
  )
}
import styled from 'styled-components';
import imageDiamond from '../../assets/img/bg-vale-sul.jpg';

const bgDiamond = `
  background-image: url(${imageDiamond});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;  
  width: 100vw;
  min-height: 100vh;
  ${props => props.bgDiamond ? bgDiamond : ''}
`;

export const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-weight: normal;
  padding: 1rem;
  font-size: 1.3rem;
`;
import styled from 'styled-components';

export const AvatarContainer = styled.div`
  width: ${props => props.size ?? '5em'};
  height: ${props => props.size ?? '5em'};
  border-radius: ${props => props.rounded ? '50%' : '3px'};
  overflow: hidden;
  img {
    max-width: 100%
  }
`;
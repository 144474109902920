import styled from 'styled-components';
import imageDiamond from '../../assets/img/bg-vale-sul.jpg';

const bgDiamond = `
  background-image: url(${imageDiamond});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;  
  width: 100vw;
  min-height: 100vh;
  ${props => props.bgDiamond ? bgDiamond : ''}
`;

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 260px;
`;

export const ContainerAvatar = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
  margin-top: 3rem;
`;

export const ContainerInformation = styled.div`
  width: 100%;
  margin-top: 1rem;
`;

export const Name = styled.h1`
  color: #fff;
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
  margin: 0;
`;

export const JobTitle = styled.h2`
  color: #fff;
  font-weight: normal;
  font-size: 1.25rem;
  text-align: center;
  margin: 0 0 1rem 0;
`;

export const Biography = styled.p`
  color: #fff;
  font-size: 1rem;
  text-align: center;
  margin: 1rem 0;
`;
import styled from 'styled-components';

export const ButtonPrimary = styled.a`
  &:hover {
    opacity: 0.90;
  }
  display: block;
  text-decoration: none;
  cursor: pointer;
  border-radius: 0.375rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-top: 0.6rem;
  margin-bottom: 0.6rem;
  color: #000;
  background: #fff;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  border: none;
  padding-left: 88px;
`;